html,
body {
  width: 100%;
  height: 100%;
}

body {
  background-color: #8dd8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex-direction: column;
}

img {
  max-width: 100%;
}

#logo {
  margin: 2rem 0;
}
